import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';


import Home from './Home';
import About from './About';
import Pricing from './Pricing';
import News from './News';
import Competences from './Competences';
import Partners from './Partners';
import { NoMatch } from './NoMatch';

import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';

import { MainLayout } from './layouts/MainLayout';
import { HomeLayout } from './layouts/HomeLayout';

import ReactGA from 'react-ga';

const trackingID = "UA-20816852-2";
ReactGA.initialize(trackingID, {
  gaOptins: {
    siteSpeedSampleRate: 100
  }
});
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
  return (
    <React.Fragment>
      <Router>
        <NavigationBar />
        <Switch>
          <AppRoute exact path="/" component={Home} layout={HomeLayout} />
          <AppRoute exact path="/about" component={About} layout={MainLayout} />
          <AppRoute exact path="/pricing" component={Pricing} layout={MainLayout} />
          <AppRoute exact path="/news" component={News} layout={MainLayout} />
          <AppRoute exact path="/competences" component={Competences} layout={MainLayout} />
          <AppRoute exact path="/partners" component={Partners} layout={MainLayout} />
          <AppRoute component={NoMatch} layout={MainLayout} />
        </Switch>
        <Footer />
      </Router>
    </React.Fragment>
  )
};

const AppRoute = ({component: Component, layout: Layout, ...rest}) => (
    <Route {...rest} render={props => (
      <Layout>
        <Component {...props} />
      </Layout>
    )} />
)

export default App;
