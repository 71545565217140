import styled from 'styled-components';

const StyledCompetencePost = styled.div`
  background-color: #1c1c1c;

  @media (max-width: 480px) {
    padding: 3vw;

    margin-top: 2vw;
    margin-bottom: 2vw;

    .title-col {
    border-bottom: 1px solid white;
    }
  }

  @media (min-width: 900px) {
    padding: 1vw;

    margin-top: 1vw;
    margin-bottom: 1vw;

    .title-col {
    border-right: 1px solid white;
    }
  }
`;

export default StyledCompetencePost;
