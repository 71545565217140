import React, { Component } from 'react';
import HomeContent from './components/HomeContent';
import Jumbotron from './components/Jumbotron';
 
class Home extends Component{
    render(){
        return(
            <React.Fragment>
                <Jumbotron />
                <HomeContent />
            </React.Fragment> 
        )
    }
}

export default Home;