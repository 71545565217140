import React from 'react';
import { Container } from 'react-bootstrap';


export const MainLayout = (props) => (
    <Container>
        {props.children}
     </Container>
)





