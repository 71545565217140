import styled from 'styled-components';

const StyledNews = styled.div`
    @media (max-width: 480px) {
        .news-box {
            margin-bottom: 12vw;
            margin-top: 12vw;

            .card-body {
                letter-spacing: 1px;
            }
        }
        .news-title {
            color: #d54848;
            font-size: 5vw;
            font-weight: bold;
        }
    }

    @media (min-width: 900px) {
        .news-box {
            margin-bottom: 3vw;
            margin-top: 3vw;
        }

        .news-title {
            color: #d54848;
            font-size: 1.5vw;
            font-weight: bold;
        }
    }

    .news-box {
        .card-body {
            background-color: #1c1c1c;
            color: white;
        }
    }

    .news-box {
        .card {
            background-color: #1c1c1c;
        }
    }
`;

export default StyledNews;