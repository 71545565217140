import styled from 'styled-components';

const StyledPricing = styled.div`
    @media (max-width: 480px) {

    }

    @media (min-width: 900px) {
        .pricing-p {
            font-size: 1vw;
        }
    }
`;

export default StyledPricing;