import React from 'react';
import { Nav, Navbar, Figure } from 'react-bootstrap';
import { Styles } from '../styles/NavigationBarStyle';
import { Link } from 'react-router-dom';

import { AiFillFacebook, AiFillYoutube } from 'react-icons/ai';


const NavigationBar = () => (
    <Styles>
        <Navbar expand="lg" style={{ width: "100%" }}>
            <Navbar.Brand href="/">
                <Figure>
                    <Figure.Image
                        width={180}
                        height={100}
                        alt="Biuro Kontrahent logo"
                        src='./assets/kontrahent-logo-cropped.png'
                    />
                </Figure>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse>
                <Nav className="justify-content-center" style={{ width: "90%" }}>
                    <Nav.Item><Nav.Link tag={Link} href="/"><h5>Strona główna</h5></Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link tag={Link} href="/about"><h5>O nas</h5></Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link tag={Link} href="/pricing"><h5>Cennik</h5></Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link tag={Link} href="/news"><h5>Aktualności</h5></Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link tag={Link} href="/competences"><h5>Kompetencje</h5></Nav.Link></Nav.Item>                    
                    <Nav.Item><Nav.Link tag={Link} href="/partners"><h5>Partnerzy</h5></Nav.Link></Nav.Item>
                </Nav>
                <Nav classname="justify-content-end">
                    <Nav.Item><Nav.Link href="https://www.facebook.com/biurokontrahent/" target="_blank"><AiFillFacebook size={60} color='#d54848' /></Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link href="https://youtube.com/playlist?list=PLh-Tthbg5FpvEf9SwDJ3XMh2XRcJFAp3C" target="_blank"><AiFillYoutube size={60} color='#d54848' /></Nav.Link></Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    </Styles>
)

export default NavigationBar;
