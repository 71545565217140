import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import StyledHomeContent from '../styles/StyledHomeContent';



const HomeContent = () => {
    return(
        <StyledHomeContent>
            <React.Fragment>
                <h1 className='main-h1'>Co wyróżnia biuro rachunkowe Kontrahent?</h1>
                <h3 className='main-h3'>Sprawdź dlaczego warto powierzyć nam prowadzenie usług ksiegowych twojej firmy</h3>
            </React.Fragment>
            <Container>
                <Row>
                    <Col xs={12} md={2}>
                        <Image src="./assets/professionalism.png" rounded style={{width: '100%', height: 'auto', maxWidth: '100px', display: 'block', margin: '0 auto'}}/>
                        <h5 style={{textAlign: 'center', marginTop: '1vw'}}>Profesjonalizm</h5>
                        <h6 style={{textAlign: 'center'}}>Przejęcie od klienta obowiązków i odpowiedzialności wobec urzędów to zadowolenie klienta</h6>
                    </Col>
                    <Col xs={12} md={2}>
                        <Image src="./assets/clock.png" rounded style={{width: '100%', height: 'auto', maxWidth: '100px', display: 'block', margin: '0 auto'}}/>
                        <h5 style={{textAlign: 'center', marginTop: '1vw'}}>Terminowość</h5>
                        <h6 style={{textAlign: 'center'}}>Priorytetem naszych działań jest zachowanie urzędowych terminów i dobrych kontaktów z urzędnikami</h6>
                    </Col>
                    <Col xs={12} md={2}>
                        <Image src="./assets/elasticity.png" rounded style={{width: '100%', height: 'auto', maxWidth: '100px', display: 'block', margin: '0 auto'}}/>
                        <h5 style={{textAlign: 'center', marginTop: '1vw'}}>Elastyczność</h5>
                        <h6 style={{textAlign: 'center'}}>Każdy klient jest traktowany indywidualnie z uwzględnieniem specyfiki jego działalności</h6>
                    </Col>
                    <Col xs={12} md={2}>
                        <Image src="./assets/experience.png" rounded style={{width: '100%', height: 'auto', maxWidth: '100px', display: 'block', margin: '0 auto'}}/>
                        <h5 style={{textAlign: 'center', marginTop: '1vw'}}>Doświadczenie</h5>
                        <h6 style={{textAlign: 'center'}}>Nasze biuro z powodzeniem działa na rynku od 2000 roku</h6>
                    </Col>
                </Row>
                <Row style={{paddingTop: '2vw'}}>
                    <Col xs={12} md={1}>
                        <Image src="./assets/auto.png" rounded style={{width: '100%', height: 'auto', maxWidth: '100px', display: 'block', margin: '0 auto'}}/> 
                    </Col>
                    <Col xs={12} md={11}>
                        <h5 className='auto-h5'>Dojeżdżamy do naszych klientów i odbieramy dokumenty</h5>
                        <h6 className='auto-h6'>
                            Jeśli biuro Twojej firmy mieści się na drugim końcu miasta i nie masz czasu dostarczyć
                            nam swoich dokumentów na czas - to nie problem! Daj nam odpowiednio wcześniej znać 
                            a nasz pracownik odbierze od Ciebie wszelkie potrzebne do rozliczenia dokumenty
                        </h6>
                    </Col>
                </Row>
            </Container>
        </StyledHomeContent>
    )
};

export default HomeContent;