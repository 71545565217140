import styled from 'styled-components';

const StyledAdvertisement = styled.div`
    .carousel {
        max-width: 750px;
        max-height: 200px;
        margin: 0 auto;

        @media (max-width: 480px) {
            width: 90%;
            height: 11vw;
            margin-bottom: 10.5vw;
        }
        @media (min-width: 900px) {
            width: 750px;
            height: 200px;
        }
    }
    .carousel-item {
        max-width: 750px;
        max-height: 200px;
    }
`;

export default StyledAdvertisement;
