import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

import StyledAbout from './styles/StyledAbout';


class About extends Component{
    render(){
        return(
            <StyledAbout>
                <React.Fragment>
                    <h1 style={{textAlign: 'center'}}>Biuro Kontrahent</h1>
                    <p className='about-p'>
                    Możecie być pewni naszych usług księgowych Kraków, ponieważ Biuro Usług i Handlu „Kontrahent” S.C. w zakresie usług rachunkowych działa na rynku od wielu lat. 
                    <br></br>
                    <br></br>
                    Celem naszego biura rachunkowego jest profesjonalne świadczenie usług księgowych dla małych i średnich podmiotów gospodarczych oraz bieżące wsparcie w finansowym zarządzaniu przedsiębiorstwami. 
                    <br></br>
                    <br></br>
                    W swojej pracy kierujemy się odpowiedzialnością oraz rzetelnością, przestrzegając terminów ustalonych z klientem. Działalność nasza objęta jest ubezpieczeniem OC.
                    <br></br>
                    <br></br>
                    Prace księgowe, płacowe i kadrowe prowadzimy korzystając z nowoczesnych programów komputerowych. Oprogramowanie jest nabyte legalnie, posiadamy stosowne licencje na poszczególne programy użytkowe. Dbając o bezpieczeństwo danych, sporządzamy bieżącą archiwizację. Powierzenie i przetwarzanie danych osobowych odbywa się wyłącznie w celu realizacji zawartej umowy i w zakresie, jaki jest niezbędny dla wywiązania się z jej postanowień. Wszelkie informacje przekazane przez Klienta, traktowane są jako poufne w czasie trwania oraz po zakończeniu kontraktu. Sprawna organizacja biura księgowego Kraków zapewnia zróżnicowaną i indywidualną obsługę w zależności od potrzeb Klientów.
                    </p>
                    <Image src="./assets/romania_trip_kontrahent.jpeg" rounded style={{width: '100%', height: 'auto', maxWidth: '', display: 'block', margin: '0 auto', marginBottom: '1vw'}}/>
                </React.Fragment> 
            </StyledAbout> 
        )
    }
}


export default About;