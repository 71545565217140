import styled from 'styled-components';

export const Styles = styled.div`
    .navbar {
        background-color: white;
        width: 100%;
    }

    .nav-item {
        margin-left: 10px;
    }

    .nav-item {
        display: block;
        width: 50;
        height: 50;
    }

    .figure {
        background-color: white;
        margin: 0 auto;
    }
    
`;