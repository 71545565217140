import React, { Component } from 'react';
import StyledNews from './styles/StyledNews';
import { Accordion, Image, Nav } from 'react-bootstrap';
import NewsArticle from './components/NewsArticle';
import Advertisement from './components/Advertisement';

class News extends Component {
    render(){
        return(
            <StyledNews>
                <React.Fragment>
                    <Accordion className="news-box" defaultActiveKey="0">
                      <NewsArticle event="43"
                        title="Wakacje od ZUS dla przedsiębiorców"
                        content={[
                          "„Wakacje od ZUS-u” to rozwiązanie, które ma odciążyć finansowo przedsiębiorców prowadzących jednoosobową działalność gospodarczą. Zgodnie z założeniami będą oni mogli raz w roku nie płacić składek na obowiązkowe ubezpieczenia społeczne.",
                          <br></br>,
                          "Możliwość zwolnienia z opłacania składek będzie obejmować składki na ubezpieczenia społeczne, Fundusz Pracy i Fundusz Solidarnościowy w dowolnie wybranym miesiącu. Składka na ubezpieczenie zdrowotne pozostaje obowiązkowa i musi być opłacana w normalnym terminie.",
                          <br></br>,
                          <br></br>,
                          "Z „wakacji od ZUS-u” przedsiębiorcy będą mogli skorzystać już od grudnia 2024 roku. Przepisy zaczną bowiem obowiązywać od 1 listopada br., a w grudniu tego roku przedsiębiorcy będą mogli skorzystać pierwszy raz z tego rozwiązania.",
                          <br></br>,
                          <br></br>,
                          "Jeśli przedsiębiorca złoży wniosek o wakacje od ZUS w grudniu to choć formalnie wakacje składkowe za grudzień 2024 roku odbędą się w 2024, to efekt tej ulgi przedsiębiorca odczuje dopiero w 2025 roku.",
                          <br></br>,
                          <br></br>,
                          "Mikroprzedsiębiorca będzie mógł raz w roku wybrać dowolny miesiąc, w którym nie będzie musiał opłacać składek ZUS, Funduszu Pracy i Funduszu Solidarnościowego. Składki za ten miesiąc zostaną pokryte z budżetu państwa.",
                          <br></br>,
                          "Należy pamiętać, że rozwiązanie to nie obejmuje składek na ubezpieczenie zdrowotne. Oznacza to, że: ",
                          <br></br>,
                          "- Przedsiębiorcy, którzy zdecydują się na skorzystanie z „wakacji składkowych”, będą nadal zobowiązani do opłacania składek na ubezpieczenie zdrowotne w wybranym miesiącu.",
                          <br></br>,
                          "- Wysokość składek zdrowotnych nie ulegnie zmianie.",
                          <br></br>,
                          "- Termin płatności składek zdrowotnych pozostanie taki sam.",
                          <br></br>,
                          "- Wspólnicy spółek cywilnych również mogą skorzystać z „wakacji od ZUS-u” pod warunkiem, że spełnią wszystkie pozostałe kryteria uprawniające do tej ulgi."
                        ]}
                      />
                      <NewsArticle event="44"
                        title="Krajowy System Faktur KSeF"
                        content={[
                          <h5 style={{textAlign: "center"}}>Co to jest KSeF (Krajowy System e-Faktur)?</h5>,
                          <br></br>,
                          "Krajowy System e-Faktur (KSeF) jest systemem komputerowym, który umożliwia generowanie i udostępnianie strukturalnych faktur. Głównym celem KSeF jest centralizacja procesu rejestracji faktur w obrocie gospodarczym poprzez skierowanie ich do jednego centralnego miejsca. W ten sposób systemy fakturowania i księgowości będą połączone z KSeF, co umożliwi przesyłanie faktur sprzedaży przez ten system. Co więcej, system KSeF umożliwia również odbieranie faktur przez systemy księgowe, które już przeszły przez KSeF. Dodatkowo, KSeF posiada funkcję zarządzania uprawnieniami, co zapewnia odpowiednie zabezpieczenia dostępu do danych.",
                          <br></br>,
                          <br></br>,
                          <h5 style={{textAlign: "center"}}>Krajowy System e-Faktur (KSeF) od kiedy?</h5>,
                          "Ministerstwo Finansów najpierw proponuje dobrowolne przystąpienie przedsiębiorców do platformy KSeF stąd od stycznia 2022 roku do końca czerwca 2024 roku korzystane z Krajowego Systemu e-Faktur jest dobrowolne. Termin wejścia w życie obowiązkowego KSeF jest już pewny. Korzystanie z KSeF ma być obowiązkowe od:",
                          <br></br>,
                          "- 1 luty 2026 roku - dla podmiotów, które w roku poprzednim przekroczyły 200 mln złotych obrotu",
                          <br></br>,
                          "- 1 kwietnia 2026 roku - dla wszystkich podmiotów",
                          <br></br>,
                          <br></br>,
                          <h5 style={{textAlign: "center"}}>KSeF jak działa?</h5>,
                          <br></br>,
                          "Krajowy System e-Faktur (KSeF) umożliwia wystawianie faktur ustrukturyzowanych w formacie XML za pomocą zintegrowanego oprogramowania finansowo-księgowego. System KSeF sprawdza, czy dokument jest zgodny ze standardem (właściwy schemat XML faktury) i nadaje mu unikalny numer. Wystawca otrzymuje urzędowe poświadczenie odbioru (UPO). Odbiorca faktury uzyskuje dostęp do niej poprzez uwierzytelnienie się w KSeF lub podanie określonych danych dotyczących faktury (tzw. dostęp anonimowy).",
                          <br></br>,
                          <br></br>,
                          "Więcej informacji znajdziecie Państwo pod linkiem: ",
                          <Nav.Link href="Dla kogo obowiązkowy KSeF? Zobacz więcej: https://poradnikprzedsiebiorcy.pl/-krajowy-system-e-faktur" target="_blank" >LINK</Nav.Link>
                        ]}
                      />
                      <NewsArticle event="45"
                        title="Pracownicze Plany Kapitałowe"
                        content={[
                          "Od 1. Stycznia 2021 Pracownicze Plany Kapitałowe obejmą wszystkie firmy, które zatrudniają pracowników.",
                          <br></br>,
                          "Pracownicze Plany Kapitałowe to dobrowolny, prywatny system długoterminowego oszczędzania, dostępny dla wszystkich osób zatrudnionych. Jest on tworzony nie przez samego pracownika, lecz wspólnie – przy jednoczesnym udziale pracodawców oraz państwa.",
                          <br></br>,
                          <br></br>,
                          "Wpłaty na konto PPK pochodzą z trzech źródeł: od pracodawcy, pracownika i państwa.",
                          <br></br>,
                          "-Pracodawca i pracownik przekazują do PPK wpłaty podstawowe (obowiązkowe). Ponadto zarówno pracodawca, jak i pracownik mogą zadeklarować przekazywanie wpłat dodatkowych (dobrowolnych). Wysokość wpłat jest naliczana procentowo od wynagrodzenia pracownika.",
                          <br></br>,
                          "-Państwo będzie dodawać do tej puli określone kwoty – niezależne od wysokości dochodów pracownika. Oszczędzający przez co najmniej 3 miesiące otrzyma od państwa jednorazowo 250 zł wpłaty powitalnej. Następnie zaś co roku, po spełnieniu określonych warunków, będzie zasilać konto pracownika kwotą 240 zł.",
                          <br></br>,
                          <br></br>,
                          "Z wynagrodzenia pracownika będzie przekazywane 2% natomiast pracodawca musi dołożyć 1,5% wartości wynagrodzenia brutto.",
                          <br></br>,
                          <br></br>,
                          "Do PPK obowiązuje tzw. automatyczny zapis osób zatrudnionych w wieku od 18 do 54 lat (od 55. roku życia pracownik samodzielnie składa wniosek o przystąpienie do programu). Podmiot zatrudniający dokonuje wyboru instytucji finansowej zarządzającej PPK, która otwiera dla pracowników imienne rachunki, na których będą gromadzone oszczędności – prywatne rachunki PPK.",
                          <br></br>,
                          <br></br>,
                          "Każdy pracownik ma jednak możliwość rezygnacji z oszczędzania w PPK. Aby tego dokonać, powinien złożyć swojemu pracodawcy deklarację rezygnacji z dokonywania wpłat do PPK.  Złożenie deklaracji rezygnacji wiąże się dla pracownika z utratą takich korzyści jak wpłaty pracodawcy czy dopłaty ze strony państwa.",
                          <br></br>,
                          <br></br>,
                          "Pracownik, który podejmie decyzję o oszczędzaniu w ramach PPK, po osiągnięciu 60. roku życia będzie mógł sam zdecydować, jak zamierza skorzystać ze zgromadzonych środków. Każdy wybierze wówczas jedno z rozwiązań, które będzie dopasowane do jego indywidualnych potrzeb.",
                          <br></br>,
                          <br></br>,
                          "-Pracownik może dalej odkładać środki w PPK.",
                          <br></br>,
                          "-Pracownik może skorzystać z domyślnej formy wypłaty: jednorazowo wypłacić 25% środków, pozostałe środki wypłacać co miesiąc przez 10 lat. Wypłata w tej formie będzie zwolniona z podatku od zysków kapitałowych i dzięki niej pracownik zyska dostęp do zgromadzonych przez siebie środków przez dłuższy okres przebywania na emeryturze – co przełoży się na jego bezpieczeństwo finansowe w dłuższym horyzoncie czasowym.",
                          <br></br>,
                          "-Pracownik nie może wskazać dowolnej kwoty do wypłaty jednorazowej, będzie to zawsze 25% środków. Jednak pozostałe oszczędności może wypłacać w dowolnej liczbie rat (również w 1 racie). Należy jednak pamiętać, że zmniejszenie liczby rat poniżej 120 będzie skutkować koniecznością zapłaty należnego podatku od zysków kapitałowych.",
                          <br></br>,
                          <br></br>,
                          "Pracownik będzie mógł też dokonać wypłaty transferowej, czyli przenieść zgromadzone środki na:",
                          <br></br>,
                          <br></br>,
                          "-polisę w zakładzie ubezpieczeń – z prawem do świadczenia okresowego lub dożywotniego,",
                          <br></br>,
                          "-rachunek terminowej lokaty oszczędnościowej, zgodnie z warunkami określonymi w ustawie o PPK,",
                          <br></br>,
                          "-pracownik może wypłacić środki w formie świadczenia małżeńskiego – wypłaty z jednego, wspólnego rachunku małżeńskiego."
                        ]}
                      />
                      <NewsArticle event="46"
                        title="Kody GTU - grupy towarów i usług"
                        content={[
                          "Od 01.10.2020 przedsiębiorcy mają obowiązek umieszczać kody GTU w plikach JPT VAT.",
                          <br></br>,
                          <br></br>,
                          "W nowej wersji JPK pojawią się elementy, które nie występowały w dotychczas przesyłanych plikach:",
                          <br></br>,
                          "-oznaczenia grupy towarów i usług (GTU)",
                          <br></br>,
                          "-oznaczenia rodzajów transakcji",
                          <br></br>,
                          "-oznaczenia dowodów sprzedaży i dowodów nabycia",
                          <br></br>,
                          <br></br>,
                          "Przedsiębiorca nie musi umieszczać symbolu GTU na fakturze sprzedażowej ale musi on być uwzględniony przy księgowaniu gdyż należy go wykazać w pliku JPK.",
                          <br></br>,
                          "W związku z tym, że przedsiębiorcy nie zgłoszeni do VATu nie wysyłają JPK VAT, nie muszą oni informować o GTU.",
                          <br></br>,
                          <br></br>,
                          <h5 style={{textAlign: "center"}}>Grupy towarów i usług (GTU)</h5>,
                          <br></br>,
                          "Nie ma obowiązku podawania kodu GTU dla każdego sprzedanego towaru lub usługi. Oznaczenia odnoszą się do tzw. towarów i usług wrażliwych, których sprzedaż jest uznawana za szczególnie narażoną na nadużycia. Katalog zawiera 10 grup towarów oraz trzy grupy usług. Znajdziemy go w rozporządzeniu Ministra Finansów, Inwestycji i Rozwoju z 15 października 2019 r. w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług.",
                          "Lista towarów i usług zaliczanych do poszczególnych grup jest bardzo szczegółowa. Przedsiębiorca przed zastosowaniem danego kodu powinien sięgnąć do wspomnianego wyżej rozporządzenia; konieczne może być też sprawdzenie przepisów dotyczących m.in. Polskiej Klasyfikacji Wyrobów i Usług (PKWiU), Nomenklatury Scalonej (CN) czy ustawy o VAT wraz załącznikami 12 i 15. Uproszczony wykaz GTU wraz z oznaczeniami przedstawia poniższa tabela:",
                          <Image src="./assets/article-images/GTU1.png" style={{display: "block", margin: "auto"}}/>,
                          <br></br>,
                          <h5 style={{textAlign: "center"}}>Oznaczenia szczególnych rodzajów transakcji</h5>,
                          "Wspomniane wyżej rozporządzenie Ministra Finansów, Inwestycji i Rozwoju wymienia również rodzaje transakcji, które należy oznaczyć w nowym JPK_VAT kodem literowym. Większość z nich dotyczy sprzedaży, ale dwa oznaczenia znajdą zastosowanie przy transakcjach stanowiących dla podatnika zakup. Przedstawia je poniższa tabela:",
                          <br></br>,
                          <Image src="./assets/article-images/GTU2.png" style={{display: "block", margin: "auto"}}/>,
                          <br></br>,
                          <h5 style={{textAlign: "center"}}>Oznaczenia dowodów sprzedaży i nabycia</h5>,
                          "Trzeci obowiązek związany z nowymi oznaczeniami w JPK dotyczy niektórych dowodów sprzedaży i dowodów nabycia. W tym wypadku dokument może mieć więcej niż jedno oznaczenie. Kody przedstawia poniższa tabela:",
                          <br></br>,
                          <Image src="./assets/article-images/GTU3.png" style={{display: "block", margin: "auto"}}/>,
                          <br></br>,
                          "Bardziej szczegółowe informacje znajdziecie Państwo pod linkiem: ",
                          <Nav.Link href="http://www.wskazniki.gofin.pl/8,447,1,jpkvat-kody-gtu-i-oznaczenia-w-ewidencji-vat.html?fbclid=IwAR03cuPhsPSMH8hH-yWrEkflo50Rt6bc9R5zSHDMoK5EXsj19wqD7eZoMzs" target="_blank" >LINK</Nav.Link>
                        ]}
                      />
                    </Accordion>
                    <Advertisement />
                </React.Fragment>
            </StyledNews>
        )
    }
}

                      // wakacje dla przedsiebiorcow, ksef nowa tabelka, wynagrodzenie minimalne



export default News;
