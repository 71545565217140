import React, { Component } from 'react';
import StyledPartners from './styles/StyledPartners';
import PartnersGenerator from './components/PartnersGenerator';
import { Container, Row } from 'react-bootstrap';

class Partners extends Component{
    render(){
        return(
            <StyledPartners>
                <Container>
                    <Row>
                        <PartnersGenerator link="https://www.davesburgers.pl/" source="./assets/logos/davesburgers_logo.jpeg"/>
                        <PartnersGenerator link="https://womai.pl/" source='./assets/logos/womai_logo.png'/>
                        <PartnersGenerator link="https://metal-drewno.pl/" source='./assets/logos/metalidrewno_logo.png'/>
                        <PartnersGenerator link="http://www.draaitauto.pl" source='./assets/logos/draait_logo.png'/>
                        <PartnersGenerator link="https://travelpropoland.pl/" source='./assets/logos/tpp_logo.png'/>
                        <PartnersGenerator link="https://krzywy.pl/" source='./assets/logos/krzywy_logo.png'/>
                        <PartnersGenerator link="https://www.facebook.com/pralnia.arida/" source='./assets/logos/arida-logo.jpg'/>
                        <PartnersGenerator link="https://www.facebook.com/BonjourPhoRestaurant/" source='./assets/logos/bonjourpho-logo.jpg'/>
                        <PartnersGenerator link="http://www.ika-technik.com.pl/" source='./assets/logos/ika-logo.jpg'/>
                        <PartnersGenerator link="https://elwolight.com/" source='./assets/logos/elwolight-logo.jpg'/>
                        <PartnersGenerator link="https://hoanghai.pl/" source='./assets/logos/hoanghai-logo.png'/>
                        <PartnersGenerator link="http://globaltelemarketing.pl/" source='./assets/logos/globaltele-logo.jpg'/>
                        <PartnersGenerator link="http://cs-light.pl/" source='./assets/logos/stagelight-logo.jpg'/>
                        <PartnersGenerator link="https://www.facebook.com/nonla123/" source='./assets/logos/nonla-logo.jpg'/>
                        <PartnersGenerator link="http://www.mar-tel.pl/" source='./assets/logos/martel-logo.jpg'/>
                        <PartnersGenerator link="https://booksy.com/pl-pl/15007_studio-urody-zaneta-kaczor_salon-kosmetyczny_8820_krakow" source='./assets/logos/zanetakaczor_logo.jpeg'/>
                        <PartnersGenerator link="https://dreamglass.pl/" source='./assets/logos/dream_glass.jfif'/>
                        <PartnersGenerator link="" source='./assets/logos/bakutil.jfif'/>
                        <PartnersGenerator link="https://www.facebook.com/profile.php?id=100083192264274&mibextid=LQQJ4d&rdid=saUerLc9oUkS3t5U&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FJG91tjfQzSk9htry%2F%3Fmibextid%3DLQQJ4d" source='./assets/logos/my_vietnam.jfif'/>
                        <PartnersGenerator link="https://www.facebook.com/profile.php?id=100057186530780" source='./assets/logos/son_anh_bar.jpg'/>
                    </Row>
                </Container>
            </StyledPartners>
        )
    }
}

export default Partners;
