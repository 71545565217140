import styled from 'styled-components';

const StyledHomeContent = styled.div`
    .container {
        max-width: 80%;
        padding: 2vw;
    }

    @media (max-width: 480px) {
        .main-h1 {
            text-align: center;
            font-size: 7vw;
        }

        .main-h3 {
            text-align: center;
            font-size: 4vw;
            margin-bottom: 3vw;
        }
        
        .auto-h5 {
            text-align: center;
            color: blue;
        }

        .auto-h6 {
            text-align: center;
        }
    }

    @media (min-width: 900px) {
        .main-h1 {
            text-align: center;
            font-size: 3vw;
        }

        .main-h3 {
            text-align: center;
            font-size: 1.5vw;
        }

        .row {
            justify-content: center;
        }

        .auto-h5 {
            text-align: left;


        .auto-h6 {
            text-align: left;
    }
`;

export default StyledHomeContent;