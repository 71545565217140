import React from 'react';
import { Accordion, Card } from 'react-bootstrap';

function NewsArticle(props) {
  return(
    <Card>
      <Accordion.Toggle className="news-title" as={Card.Header} eventKey={props.event}>
        {props.title}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={props.event}>
      <Card.Body>
        {props.content}
      </Card.Body>
      </Accordion.Collapse>
    </Card>
  )
}

export default NewsArticle;
