import styled from 'styled-components';


// POMYŚLEĆ JAK PRZYKLEIĆ FOOTER DO DOŁU
const StyledFooter = styled.div`
    .container {
        background-color: #1c1c1c;
        max-width: 100%;
        color: white;

        position: relative;
        left: 0;
        bottom: 0;
        right: 0;
    }

    @media (max-width: 480px) {
        .container {
            padding: 4vw;
        }
    }

    @media (min-width: 900px) {
        .container {
            padding: 5vw;
        }
    }
`;

export default StyledFooter;

//  position: absolute:
//  bottom: 0;
//  width: 100%;
//  margin-top: 6.5vw;