import React from 'react';
import { Carousel } from 'react-bootstrap';
import StyledAdvertisement from '../styles/StyledAdvertisement';

const Advertisement = () => (
    <StyledAdvertisement>
       <Carousel controls={false} indicators={false}>
            <Carousel.Item>
                <a href="https://tc.tradetracker.net/?c=30347&amp;m=1555474&amp;a=382760&amp;r=&amp;u="
                target="_blank"
                rel="noopener noreferrer">
                <img
                className="d-block w-100"
                src="https://ti.tradetracker.net/?c=30347&amp;m=1555474&amp;a=382760&amp;r=&amp;t=html"
                alt="perfumy.pl"
                />
                </a>
            </Carousel.Item>
            <Carousel.Item>
                <a href="https://tc.tradetracker.net/?c=28822&amp;m=1821842&amp;a=382760&amp;r=&amp;u="
                target="_blank"
                rel="noopener noreferrer">
                <img
                className="d-block w-100"
                src="https://ti.tradetracker.net/?c=28822&amp;m=1821842&amp;a=382760&amp;r=&amp;t=html"
                alt="intersport.pl"
                />
                </a>
            </Carousel.Item>
            <Carousel.Item>
                <a href="https://tc.tradetracker.net/?c=17098&amp;m=1684555&amp;a=382760&amp;r=&amp;u="
                target="_blank"
                rel="noopener noreferrer">
                <img
                className="d-block w-100"
                src="https://ti.tradetracker.net/?c=17098&amp;m=1684555&amp;a=382760&amp;r=&amp;t=html"
                alt="aliexpress.com"
                />
                </a>
            </Carousel.Item>
        </Carousel>
    </StyledAdvertisement>
)

export default Advertisement;
