import React from 'react';
import { Jumbotron as Jumbo, Container, Overlay } from 'react-bootstrap';

import { Styles, StyledH, StyledP } from '../styles/JumboStyle';

const Jumbotron = () => (
    <Styles>
        <Jumbo fluid className="jumbo">
            <Overlay></Overlay>
            <Container>
                <StyledH>Wybierz najlepsze rozwiązanie dla Ciebie</StyledH>
                    <StyledP>
                        Przychodząc do naszej firmy zyskujesz pewność, że księgowość twojej firmy trafia w najlepsze ręce
                    </StyledP>
            </Container>
        </Jumbo>
    </Styles>
)

export default Jumbotron;