import styled from 'styled-components';

//background: url(${'./assets/dubai-desert.jpg'});
export const Styles = styled.div`
    .jumbo {
        background-color: white;
        background-size: cover;
        background: url(${'./assets/main-image.png'});
        color: #ccc;
        width: 100%;
        height: auto;
        
        position: relative;
        z-index: -2;

        display: flex;
        display-direction: column;
        justify-content: center;
        align-items: center;

        @media (min-width: 900px) {
            min-height: 50vw;
            max-height: 50vw;
        }

        @media (max-width: 480px) {
            max-height: 45vw;
        }
    }

    .overlay {
        background-color: #000;
        opacity: 0.6;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
    }

    .container {
        text-align: center;
        
        @media (max-width: 480px) {
            opacity: 0.8;
        }
    }
`;

export const StyledH = styled.h1`
    color: #E54C4C;
    padding: 2px;

    @media (max-width: 480px) {
        font-size: 7vw;
    }
    @media (min-width: 900px) {
        font-size: 4vw;
    }
`;

export const StyledP = styled.p`
    color: white;
    letter-spacing: 2px;

    @media (max-width: 480px) {
        font-size: 3vw;
    }
    @media (min-width: 900px) {
        font-size: 1vw;
    }
`;