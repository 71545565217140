import styled from 'styled-components';

const StyledPartnersGenerator = styled.div`

`;

export default StyledPartnersGenerator;

/*

  .partner-image {
    max-width: 250px;
    max-height: 180px;

    margin-top: 2vw;
    margin-bottom: 2vw;
  }

*/