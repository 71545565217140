import React from 'react';
import { Row, Col } from 'react-bootstrap';
import StyledCompetencePost from '../styles/StyledCompetencePost';

function CompetencePost(props) {
  return(
    <React.Fragment>
      <StyledCompetencePost>
        <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Col xs={12} md={3} className="title-col">
            <h4 style={{textAlign: "center", color: "#e54c4c"}}>{props.title}</h4>
          </Col>
          <Col xs={12} md={9}>
            <h5 style={{textAlign: "center", color: 'white'}}>{props.content}</h5>
          </Col>
        </Row>
      </StyledCompetencePost>
    </React.Fragment>
  )
}

export default CompetencePost;
