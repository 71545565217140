import React from 'react';
import { Container, Col, Row, Nav } from 'react-bootstrap';
import { AiFillFacebook, AiFillYoutube } from 'react-icons/ai';

import StyledFooter from '../styles/StyledFooter';

const Footer = () => (
    <StyledFooter>
        <React.Fragment>
            <Container>
                <Row>
                    <Col xs={12} md={4}>
                        <h3>Godziny otwarcia</h3>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} md={2}>
                        <h5 style={{color: '#e54c4c'}}>Poniedziałek</h5>
                        <h5>08:30 - 16:00</h5>
                    </Col>
                    <Col xs={4} md={2}>
                        <h5 style={{color: '#e54c4c'}}>Wtorek</h5>
                        <h5>08:30 - 16:00</h5>
                    </Col>
                    <Col xs={4} md={2}>
                        <h5 style={{color: '#e54c4c'}}>Środa</h5>
                        <h5>08:30 - 16:00</h5>
                    </Col>
                    <Col xs={4} md={2}>
                        <h5 style={{color: '#e54c4c'}}>Czwartek</h5>
                        <h5>08:30 - 16:00</h5>
                    </Col>
                    <Col xs={4} md={2}>
                        <h5 style={{color: '#e54c4c'}}>Piątek</h5>
                        <h5>08:30 - 16:00</h5>
                    </Col>
                    <Col xs={4} md={2}>
                        <Nav classname="justify-content-end">
                            <Nav.Item><Nav.Link href="https://www.facebook.com/biurokontrahent/" target="_blank"><AiFillFacebook size={60} color='#d54848' /></Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link href="https://youtube.com/playlist?list=PLh-Tthbg5FpvEf9SwDJ3XMh2XRcJFAp3C" target="_blank"><AiFillYoutube size={60} color='#d54848' /></Nav.Link></Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                <Row style={{marginTop: '1vw'}}>
                    <Col>
                        <h5>Biuro Kontrahent - Nowa Huta</h5>
                        <h5>ul. Makuszyńskiego 4</h5>
                        <h5>31-752 Kraków</h5>
                    </Col>
                    <Col>
                        <h5>
                            E-mail: kontrahent.pk@gmail.com
                        </h5>
                    </Col>
                    <Col>
                        <h5>Telefon stacjonarny: 12 416 22 62</h5>
                        <h5>Telefon komórkowy: 880 540 034</h5>
                    </Col>
                </Row>

                <Row style={{border: '1px solid white'}}/>


                  <Row>
                      <Col xs={12} md={4}>
                          <h3>Godziny otwarcia</h3>
                      </Col>
                  </Row>
                  <Row>
                      <Col xs={4} md={2}>
                          <h5 style={{color: '#e54c4c'}}>Poniedziałek</h5>
                          <h5>09:00 - 15:00</h5>
                      </Col>
                      <Col xs={4} md={2}>
                          <h5 style={{color: '#e54c4c'}}>Wtorek</h5>
                          <h5>09:00 - 15:00</h5>
                      </Col>
                      <Col xs={4} md={2}>
                          <h5 style={{color: '#e54c4c'}}>Środa</h5>
                          <h5>09:00 - 15:00</h5>
                      </Col>
                      <Col xs={4} md={2}>
                          <h5 style={{color: '#e54c4c'}}>Czwartek</h5>
                          <h5>09:00 - 15:00</h5>
                      </Col>
                      <Col xs={4} md={2}>
                          <h5 style={{color: '#e54c4c'}}>Piątek</h5>
                          <h5>09:00 - 15:00</h5>
                      </Col>
                </Row>
                <Row style={{marginTop: '1vw'}}>
                    <Col>
                      <h5>Biuro Kontrahent - Krakowskie CHT</h5>
                      <h5>ul. Krzywda 1</h5>
                      <h5>30-710 Kraków</h5>
                      <h5>Lokal 303, 1 piętro</h5>
                    </Col>
                    <Col>
                      <h5>
                          E-mail: kontrahent.pk@gmail.com
                      </h5>
                    </Col>
                    <Col>
                      <h5>Telefon komórkowy: 518 972 063</h5>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    </StyledFooter>
)

export default Footer;