import React from 'react';
import { Nav, Col, Image } from 'react-bootstrap';
import StyledPartnersGenerator from '../styles/StyledPartnersGenerator';


function PartnersGenerator(props) {
    return(
        <Col xs={12} md={3} className="m-auto">
            <StyledPartnersGenerator>
              <Nav.Link href={props.link} target="_blank">
                  <Image src={props.source} alt="" className="d-block mx-auto" fluid/>
              </Nav.Link>
            </StyledPartnersGenerator>
        </Col>
)}

export default PartnersGenerator;