import styled from 'styled-components';

const StyledAbout = styled.div`
    @media (max-width: 480px) {
        .about-p {
            font-size: 4vw;
        }
    }

    @media (min-width: 900px){
        .about-p {
            font-size: 1vw;
        }
    }
`;

export default StyledAbout;