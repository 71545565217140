import React, { Component } from 'react';
import CompetencePost from './components/CompetencePost';

class Competences extends Component{
  render(){
    return(
      <React.Fragment>
      <CompetencePost
        title="Dyżur telefoniczny w języku wietnamskim"
        content="Oferujemy nową usługę - dyżur telefoniczny dla osób posługujących się językiem wietnamskim. Jeśli potrzebujesz porady lub tłumaczenia spraw związanych z księgowością, to właśnie dla Ciebie. Kontaktować można się codziennie (z wyłączeniem weekendów) w godzinach 10-12 pod numerem 502 663 346"
      />
      <CompetencePost
        title="Oprogramowanie Enova"
        content="Biuro korzysta z bardzo nowoczesnego systemu księgowego ENOVA. Program modułowy pozwala kontrolować prace z wielu zakresów jednocześnie tzn. pełną księgowość, książkę przychodów  i rozchodów, ryczałt, vat, kadry i płace, zus i inne. System pozwala na pracę zdalną oraz współpracuje z innymi systemami co znacząco przyspiesza i automatyzuje pracę w biurze. Program jest na bieżąco aktualizowany i dostosowywany do zmieniających się przepisów podatkowych i płacowo-kadrowych. Zarządza jednocześnie nieograniczoną liczbą baz a system informatyczny w biurze zabezpiecza dane poprzez automatyczne kopie bezpieczeństwa oraz własny serwer. Z tego systemu korzysta również Stowarzyszenie Księgowych w Polsce."
      />
      <CompetencePost
        title="Saldeo Smart"
        content="Saldeo Smart - Odczyt dokumentów to użyteczne narzędzie, które umożliwia seryjne księgowanie dokumentów, czy to prosto z wersji papierowej czy to z wersji elektronicznej. Dzięki takiemu narzędziu  praca księgowych jest zoptymalizowana. Otwiera ono również możliwość pracy biura wyłącznie w formie elektronicznej, co oznacza że klient wymienia z biurem komplet dokumentów tylko w niepapierowej formie i bez konieczności pojawiania się w biurze, pozostaje tylko  kontakt zdalny."
      />
      <CompetencePost
        title="Obsługa obcokrajowców"
        content="W naszym biurze zebraliśmy spore doświadczenie odnośnie współpracy z klientami obcokrajowcami. Klienci nasi pochodzą z takich krajów jak: Wietnam, Korea, Meksyk, Wielka Brytania, Ukraina. Wielu z nich nie posługuje się językiem polskim w sposób komunikacyjny, pomimo to jesteśmy w stanie prowadzić ich sprawy firmowe w sposób bezkonfliktowy. Nasi pracownicy posługują się językiem angielskim w kontaktach z klientami, którzy są w stanie w tym języku operować, a z pozostałymi kontakt odbywa się za pomocą zaprzyjaźnionych tłumaczy. Nasze kanały informacji z klientem również posługują się różnymi wersjami językowymi dla ułatwienia zrozumienia przekazu dla obcokrajowców."
      />
      <CompetencePost
        title="Punkt biurowy w Krakowskim CHT"
        content="W grudniu 2024 roku otworzyliśmy nasz drugi punkt biurowy z lokalizacją w centrum handlowym Krakowskie CHT przy ulicy Krzywda 1. Biuro pomimo dwóch odrębnych lokalizacji pracuje na jednolitym systemie księgowym, co pozwala klientom załatwiać bieżące sprawy dowolnie w którymkolwiek punkcie w zależności od potrzeb."
      />
      <CompetencePost
        title="Rejon Nowa Huta"
        content="Nasze biuro mieści się w dzielnicy Nowa Huta. Dla wielu klientów duże znaczenie ma lokalizacja i nie przez przypadek znacząca część naszych klientów pochodzi z rejonu Nowej Huty. Bliskość i łatwy podjazd samochodem, ochrona budynku, godziny otwarcia to wszystko pomaga naszym klientom w kontaktach z naszym biurem. Z wieloma klientami jesteśmy umówieni, że dokumenty do księgowania odbieramy, jako biuro, prosto z siedziby firmy."
      />
      <CompetencePost
        title="Urząd Skarbowy w Proszowicach"
        content="Podczas wieloletnich kontaktów i współpracy z Urzędem Skarbowym w Proszowicach udało nam się wypracować bardzo dobre relacje. Pracownicy US są bardzo przystępni i każdorazowo starają się wspólnie z nami jak najszybciej i efektywnie załatwić sprawę. Takie podejście Urzędników jest bezcenne przy prowadzeniu działalności gospodarczej naszych klientów."
      />
      </React.Fragment>
    )
  }
}

export default Competences;
