import React, { Component } from 'react';
import StyledPricing from './styles/StyledPricing';

class Pricing extends Component {
    render(){
        return(
            <StyledPricing>
                <React.Fragment>
                    <p className='pricing-p'>
                    Cennik biura rachunkowego Kraków – Wysokość cen uzależniony jest od wielkości firmy oraz ilości dokumentów. Przykładowe ceny netto usług księgowych to:
                    <br></br>
                    <br></br>
                    <li>Karta podatkowa od 150,-</li>
                    <br></br>
                    <li>Ryczałt od 200,-</li>
                    <br></br>
                    <li>Książka przychodów od 250,-</li>
                    <br></br>
                    <li>Kadry od pracownika od 30,-</li>
                    <br></br>
                    <li>Pełna księgowość od 1200,-</li>
                    <br></br>
                    Zapraszamy do naszego biura rachunkowego w Nowej Hucie!
                    </p>
                    <br></br>
                    <br></br>
                </React.Fragment>
            </StyledPricing>
        )
    }
}

export default Pricing;